import Dashboard from '@/layouts/Dashboard.vue';

const dashboard = [
  {
    path: '/dashboard',
    component: Dashboard,
    meta: {
      requiredAuth: true,
    },
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/Home.vue'),
      },
      {
        path: 'community-manager/upload-leads',
        name: 'LeadsList',
        component: () => import('@/views/dashboard/community-manager/leads/List.vue'),
      },
      {
        path: 'digital-channel/leads',
        name: 'DigitalChannelLeadsList',
        component: () => import('@/views/dashboard/digital-channel/List.vue'),
      },
      /* {
        path: "sales-consultant/customers",
        name: "SalesConsultantCustomerList",
        component: () => import("@/views/dashboard/customers/List.vue"),
      }, */
      {
        path: 'sales-consultant/leads',
        name: 'SalesConsultantLeadsList',
        component: () => import('@/views/dashboard/sales-consultant/leads/List.vue'),
      },
      {
        path: 'sales-consultant/biddings-information',
        name: 'SalesConsultantBiddingsInformationList',
        component: () =>
          import('@/views/dashboard/sales-consultant/biddings/ListBiddingInformation.vue'),
      },
      {
        path: 'sales-consultant/evaluation-requests',
        name: 'SalesConsultantsERList',
        component: () => import('@/views/dashboard/sales-consultant/evaluation-request/List.vue'),
      },
      {
        path: 'sales-consultant/appraisals',
        name: 'AppraisalsList',
        component: () => import('@/views/dashboard/sales-consultant/appraisals/List.vue'),
      },
      {
        path: 'sales-consultant/appraisals/:id/show',
        name: 'AppraisalShowPage',
        component: () => import('@/views/dashboard/sales-consultant/appraisals/Show.vue'),
        props: true,
      },
      {
        path: 'sales-consultant/inmatriculaciones/list',
        name: 'ListInmatriculaciones',
        component: () =>
          import('@/views/dashboard/sales-consultant/inmatriculaciones/ListInmatriculaciones.vue'),
      },
      {
        path: 'sales-consultant/units-purchased',
        name: 'AVListUnitsPurchased',
        component: () => import('@/views/dashboard/sales-consultant/units-purchased/List.vue'),
      },
      {
        path: 'sales-consultant/my-sales',
        name: 'MySalesList',
        component: () => import('@/views/dashboard/sales-consultant/my-sales/ListMySales.vue'),
      },
      {
        path: 'maf/lists',
        name: 'MafList',
        component: () => import('@/views/dashboard/maf/ListMain.vue'),
      },
      {
        path: 'maf/plans',
        name: 'MafPlans',
        component: () => import('@/views/dashboard/maf/plans/List.vue'),
      },
      {
        path: 'matriz/lists',
        name: 'MatrizList',
        component: () => import('@/views/dashboard/matriz/List.vue'),
      },
      {
        path: 'adv/listsbells',
        name: 'ListBells',
        component: () => import('@/views/dashboard/adv/bells/List.vue'),
      },
      {
        path: 'adv/listsTypesBells',
        name: 'ListTypesBells',
        component: () => import('@/views/dashboard/adv/bells/ListTypesBells.vue'),
      },
      {
        path: 'adv/ListDetailBell/:bellId?',
        name: 'ListDetailBell',
        component: () => import('@/views/dashboard/adv/bells/ListDetailBell.vue'),
      },
      {
        path: 'preowned-vehicles/appraisal-request',
        name: 'SeminuevosApparaisalRequestsList',
        component: () => import('@/views/dashboard/preowned-vehicles/appraisal-request/List.vue'),
      },
      {
        path: 'preowned-vehicles/assign-advisers',
        name: 'AssignAdvisersPage',
        component: () => import('@/views/dashboard/preowned-vehicles/AssignAdvisers.vue'),
      },
      {
        path: 'preowned-vehicles/list-appraisal-request',
        name: 'AssignAdvisersPage',
        component: () =>
          import('@/views/dashboard/preowned-vehicles/appraisal-request/ListForPreowned.vue'),
      },
      {
        path: 'preowned-vehicles/vehicles-list',
        name: 'VehiclesListAppraisalPage',
        component: () => import('@/views/dashboard/preowned-vehicles/vehicles-list/List.vue'),
      },
      {
        path: 'adv/vehicles',
        name: 'AdvVehicleListPage',
        component: () => import('@/views/dashboard/adv/vehicles-list/List.vue'),
      },
      {
        path: 'adv/reserve-maintenance',
        name: 'AdvReserveMaintenanceListPage',
        component: () => import('@/views/dashboard/adv/reserve-maintenance/List.vue'),
      },
      {
        path: 'appointments/list',
        name: 'AppointmentsList',
        component: () => import('@/views/dashboard/appointments/AppointmentsList.vue'),
      },
      {
        path: 'inmatriculaciones/list',
        name: 'List',
        component: () => import('@/views/dashboard/inmatriculaciones/List.vue'),
      },
      {
        path: 'inmatriculaciones/rotary-plate-applications/list',
        name: 'List',
        component: () => import('@/views/dashboard/inmatriculaciones/RequestList.vue'),
      },
      {
        path: 'inmatriculaciones/details/:id',
        name: 'DetailsInmatriculaciones',
        component: () => import('@/views/dashboard/inmatriculaciones/details/Main.vue'),
      },
      {
        path: 'inmatriculaciones/rotary-plates/list',
        name: 'ListRotaryPlates',
        component: () => import('@/views/dashboard/inmatriculaciones/rotary-plates/PlatesList.vue'),
      },
      {
        path: 'inmatriculaciones/rotary-plates/request-list',
        name: 'ListRequestRotaryPlates',
        component: () =>
          import('@/views/dashboard/inmatriculaciones/rotary-plates/RequestList.vue'),
      },
      {
        path: 'inmatriculaciones/rotary-plates/history-list',
        name: 'ListHistoryRotaryPlates',
        component: () =>
          import('@/views/dashboard/inmatriculaciones/rotary-plates/HistoryList.vue'),
      },
      {
        path: 'autotuning/deliverySchedule',
        name: 'DeliverySchedule',
        component: () => import('@/views/dashboard/autotuning/delivery-schedule/List.vue'),
      },
      {
        path: 'autotuning/listGeneral',
        name: 'autotunimgListGeneral',
        component: () => import('@/views/dashboard/autotuning/delivery-schedule/ListGeneral.vue'),
      },
      {
        path: 'autotuning/list-sales',
        name: 'ListSales',
        component: () => import('@/views/dashboard/autotuning/delivery-schedule/ListSales.vue'),
      },
      {
        path: 'autotuning/detail-vehicle',
        name: 'DetailVehicle',
        component: () => import('@/views/dashboard/autotuning/delivery-schedule/DetailVehicle.vue'),
      },
      {
        path: 'autotuning/list-transfer',
        name: 'ListTransfer',
        component: () => import('@/views/dashboard/autotuning/transfer/List.vue'),
      },
      {
        path: 'autotuning/:id/list-programming-accesories/:ocv_id',
        name: 'ListProgrammingAccesoriesAutoTuning',
        component: () => import('@/views/dashboard/pds/ListProgrammingAccesories.vue'),
      },
      {
        path: 'adv/vehicles',
        name: 'AdvVehicleListPage',
        component: () => import('@/views/dashboard/adv/vehicles-list/List.vue'),
      },
      {
        path: 'autotuning/list-programming',
        name: 'ListProgramming',
        component: () =>
          import('@/views/dashboard/autotuning/delivery-schedule/ListProgramming.vue'),
      },
      {
        path: 'autotuning/persons',
        name: 'ListProgramming',
        component: () => import('@/views/dashboard/persons/List.vue'),
      },
      {
        path: 'adv/assigned-vehicles',
        name: 'AdvAssignedVehicleListPage',
        component: () => import('@/views/dashboard/adv/assigned-vehicles/List.vue'),
      },
      {
        path: 'adv/schedule-deliveries',
        name: 'ScheduleDeliveriestPage',
        component: () => import('@/views/dashboard/adv/schedule-deliveries/List.vue'),
      },
      {
        path: 'adv/units-list',
        name: 'AdvUnitsListPage',
        component: () => import('@/views/dashboard/adv/units-list/List.vue'),
      },
      {
        path: 'adv/vehicles-list/:id/details',
        name: 'AdvVehicleDetailsPage',
        component: () => import('@/views/dashboard/adv/vehicles-list/Details.vue'),
      },
      {
        path: 'adv/list-sales-pending',
        name: 'AdvListSalesPendingPage',
        component: () => import('@/views/dashboard/adv/list-ocvs/List.vue'),
      },
      {
        path: 'adv/campaigns-collect',
        name: 'AdvListCampaignsCollectPage',
        component: () => import('@/views/dashboard/adv/bells-to-collect/LisBellsToCollect.vue'),
      },
      {
        path: 'adv/leads',
        name: 'AdvLeadsPage',
        component: () => import('@/views/dashboard/adv/leads/List.vue'),
      },
      {
        path: 'adv/sales-onhold',
        name: 'AdvLeadSaleOnHoldPage',
        component: () => import('@/views/dashboard/adv/leads/List.vue'),
      },
      {
        path: 'adv/sales',
        name: 'AdvLeadSalesPage',
        component: () => import('@/views/dashboard/adv/sales/List.vue'),
      },
      {
        path: 'reception/list',
        name: 'ListReception',
        component: () => import('@/views/dashboard/reception/ListReception.vue'),
      },
      {
        path: 'transfers/assigned/list',
        name: 'ListAssigned',
        component: () => import('@/views/dashboard/transfers/ListAssigned.vue'),
      },
      {
        path: 'transfers/vehicle-transfers/list',
        name: 'ListVehicleTransfers',
        component: () => import('@/views/dashboard/transfers/ListVehicleTransfers.vue'),
      },
      {
        path: 'transfers/vehicle-transfers/gantt/:id',
        name: 'TransfersGanttView',
        component: () => import('@/views/dashboard/transfers/TransfersGanttView.vue'),
      },
      {
        path: 'transfers/incidents/list',
        name: 'ListTransfersIncidents',
        component: () => import('@/views/dashboard/transfers/incidents/ListIncidents.vue'),
      },
      {
        path: 'transfers/incidents/:id/form/show',
        name: 'ListTransfersIncidentsFormShow',
        component: () => import('@/views/dashboard/transfers/incidents/IncidentForm'),
      },
      {
        path: 'transfers/incidents/:adv_vehicle_id/create',
        name: 'ListTransfersIncidentsFormCreate',
        component: () => import('@/views/dashboard/transfers/incidents/IncidentForm'),
      },
      {
        path: 'pds/list',
        name: 'ListPds',
        component: () => import('@/views/dashboard/pds/ListPds.vue'),
      },
      {
        path: 'pds/listCalendar',
        name: 'ListCalendar',
        component: () => import('@/views/dashboard/pds/ListCalendar.vue'),
      },
      {
        path: 'pds/:id/list-programming/:global_sale_id',
        name: 'ListProgrammingAccesories',
        component: () => import('@/views/dashboard/pds/ListProgrammingAccesories.vue'),
      },
      {
        path: 'pds/delivery/list',
        name: 'ListDelivery',
        component: () => import('@/views/dashboard/pds/delivery/ListDelivery.vue'),
      },
      {
        path: 'pds/delivery/form/:id',
        name: 'DeliveryVehicleMain',
        component: () => import('@/views/dashboard/pds/delivery/DeliveryForm.vue'),
      },

      {
        path: 'vigilance/list-control',
        name: 'ListControl',
        component: () => import('@/views/dashboard/vigilance/ListControl.vue'),
      },
      {
        path: 'vigilance/list-reports',
        name: 'ListReports',
        component: () => import('@/views/dashboard/vigilance/ListReports.vue'),
      },
      {
        path: 'finances/exchange-rate',
        name: 'FinancesExchangeRatePage',
        component: () => import('@/views/dashboard/finances/exchange-rate/List.vue'),
      },
      {
        path: 'vehicle-details/:id',
        name: 'DetailsVehicleMain',
        component: () => import('@/views/dashboard/vehicle-details/DetailsVehicleMain.vue'),
      },
      {
        path: 'vehicles/categories',
        name: 'VehiclesCategoriesList',
        component: () => import('@/views/dashboard/vehicles/categories/List.vue'),
      },
      {
        path: 'vehicles/years',
        name: 'VehiclesYearsList',
        component: () => import('@/views/dashboard/vehicles/years/List.vue'),
      },
      {
        path: 'vehicles/models',
        name: 'VehiclesModelsList',
        component: () => import('@/views/dashboard/vehicles/models/List.vue'),
      },
      {
        path: 'vehicles/versions',
        name: 'VehiclesVerionsList',
        component: () => import('@/views/dashboard/vehicles/versions/List.vue'),
      },
      {
        path: 'vehicles/colors',
        name: 'VehiclesColorsList',
        component: () => import('@/views/dashboard/vehicles/colors/List.vue'),
      },
      {
        path: 'vehicles/attributes',
        name: 'VehiclesAttributesList',
        component: () => import('@/views/dashboard/vehicles/attributes/List.vue'),
      },
      {
        path: 'vehicles/group-attributes',
        name: 'VehiclesGroupAttributesList',
        component: () => import('@/views/dashboard/vehicles/group-attributes/List.vue'),
      },
      {
        path: 'vehicles/classes',
        name: 'VehiclesClassesList',
        component: () => import('@/views/dashboard/vehicles/classes/List.vue'),
      },
      {
        path: 'vehicles/types',
        name: 'VehiclesTypesList',
        component: () => import('@/views/dashboard/vehicles/types/List.vue'),
      },
      {
        path: 'vehicles/vehicles',
        name: 'VehiclesList',
        component: () => import('@/views/dashboard/vehicles/vehicles/List.vue'),
      },
      {
        path: 'vehicles/vehicles/:id/prices',
        name: 'VehiclePricesList',
        component: () => import('@/views/dashboard/vehicles/prices/List.vue'),
      },
      {
        path: 'vehicles/vehicles/:id/data-sheet',
        name: 'VehicleDataSheetPage',
        component: () => import('@/views/dashboard/vehicles/vehicles/DataSheet.vue'),
      },
      {
        path: 'provisioning/units-report',
        name: 'ProvisioningUnitsReportPage',
        component: () => import('@/views/dashboard/provisioning/List.vue'),
      },
      // ACTIVOS-CONEXOS

      {
        path: ':id/money-laundering/billing',
        name: 'MoneyLaunderingList',
        component: () => import('@/views/dashboard/money-laundering/List.vue'),
      },
      {
        path: 'money-laundering/sales-report',
        name: 'MoneyLaunderingSalesReport',
        component: () =>
          import('@/views/dashboard/money-laundering/ocvs-reports/ListOcvReports.vue'),
      },
      {
        path: 'money-laundering/anticipations',
        name: 'MoneyLaunderingAnticipations',
        component: () =>
          import(
            '@/views/dashboard/money-laundering/anticipations/ListLaunderingAnticipations.vue'
          ),
      },
      {
        path: 'biddings/prospects',
        name: 'BiddingsListProspects',
        component: () => import('@/views/dashboard/biddings/prospects/ListProspects.vue'),
      },
      {
        path: 'biddings/reservations',
        name: 'BiddingsListReservations',
        component: () => import('@/views/dashboard/biddings/reservations/ListReservations.vue'),
      },
      {
        path: 'biddings/quotations/:id/efficacy',
        name: 'BiddingsQuotationEfficacy',
        component: () => import('@/views/dashboard/biddings/quotations/efficacy/MainEfficacy.vue'),
      },
      {
        path: 'biddings/adv-requests',
        name: 'BiddingsListProspectsAdv',
        component: () => import('@/views/dashboard/biddings/prospects/ListProspects.vue'),
        props: { moduleRoute: 'adv' },
      },
      {
        path: 'biddings/conexos-requests',
        name: 'BiddingsListProspectsConexos',
        component: () => import('@/views/dashboard/biddings/prospects/ListProspects.vue'),
        props: { moduleRoute: 'conexos' },
      },
      {
        path: 'biddings/autotuning-requests',
        name: 'BiddingsListProspectsAutotuning',
        component: () => import('@/views/dashboard/biddings/prospects/ListProspects.vue'),
        props: { moduleRoute: 'autotuning' },
      },

      /* CONEXOS */
      {
        path: 'conexos/collective-fund',
        name: 'CollectiveFundList',
        component: () => import('@/views/dashboard/conexos/collective-fund/ListMain.vue'),
      },
      {
        path: 'conexos/assing-advisers',
        name: 'ConexosListAssignAdvisers',
        component: () => import('@/views/dashboard/conexos/advisers/ListAssignAdvisers.vue'),
      },
      {
        path: 'conexos/physical-soat',
        name: 'ConexosListPhysicalSoats',
        component: () => import('@/views/dashboard/conexos/physical-soat/ListPhysicalSoats.vue'),
      },
      {
        path: 'conexos/vehicles',
        name: 'ConexosVehiclesList',
        component: () => import('@/views/dashboard/conexos/vehicles/ListVehiclesMain.vue'),
      },
      {
        path: 'conexos/ocv/:id/edit',
        name: 'ShowOcv',
        component: () => import('@/views/dashboard/sales/ocv/CreateEditOcv.vue'),
      },
      {
        path: 'conexos/vehicles/:id/tracking',
        name: 'ConexosVehicleTracking',
        component: () =>
          import(
            '@/views/dashboard/conexos/vehicles/vehicle-tracking-internal/VehicleTrackingMain.vue'
          ),
      },
      {
        path: 'conexos/vehicles/:id/tracking',
        name: 'ConexosVehiclesTracking',
        component: () => import('@/views/dashboard/conexos/vehicles/Tracking.vue'),
      },

      {
        path: 'conexos/sales/:article_id?',
        name: 'ConexosSalesList',
        component: () => import('@/views/dashboard/conexos/sales/ListMainSales.vue'),
      },
      {
        path: 'conexos/sales/:id/details-sales',
        name: 'ConexosSaleDetailsMainShow',
        props: { disabledSale: true },
        component: () =>
          import('@/views/dashboard/conexos/sales/sales-details/SaleDetailsMain.vue'),
      },
      {
        path: 'conexos/sales/:id/details-sales',
        name: 'ConexosSaleDetailsMainEdit',
        props: { disabledSale: false },
        component: () =>
          import('@/views/dashboard/conexos/sales/sales-details/SaleDetailsMain.vue'),
      },
      {
        path: 'conexos/renovations',
        name: 'ConexosRenovationsList',
        component: () => import('@/views/dashboard/conexos/renovations/ListRenovations.vue'),
      },
      {
        path: 'conexos/gps/:id/sheet',
        name: 'ConexosGpsSheet',
        component: () => import('@/views/dashboard/conexos/sales/lists/gps/GpsSheetMain.vue'),
      },
      {
        path: 'cashier/derivations',
        name: 'CashierDerivationsList',
        component: () => import('@/views/dashboard/cashier/derivations/ListMain.vue'),
      },
      {
        path: 'cashier/deposits',
        name: 'CashierDepositsList',
        component: () => import('@/views/dashboard/cashier/deposits/ListDeposits.vue'),
      },
      {
        path: 'vehicles/drive-types',
        name: 'DriveTypesVehicleList',
        component: () => import('@/views/dashboard/vehicles/drive-types/List.vue'),
      },

      /* SALES */

      {
        path: 'sales/ocv',
        name: 'OcvPage',
        component: () => import('@/views/dashboard/sales/ocv/List.vue'),
      },
      {
        path: 'sales/ocv/create-edit-ocv',
        name: 'CreateEditOcv',
        component: () => import('@/views/dashboard/sales/ocv/CreateEditOcv.vue'),
      },
      {
        path: 'sales/ocv/create',
        name: 'CreateOcv',
        component: () => import('@/views/dashboard/sales/ocv/CreateEditOcv.vue'),
      },
      {
        path: 'sales/ocv/:id/edit',
        name: 'EditOcv',
        component: () => import('@/views/dashboard/sales/ocv/CreateEditOcv.vue'),
      },
      {
        path: 'sales/quotations/create',
        name: 'CreateEditQuotation',
        component: () => import('@/views/dashboard/sales/quotations/CreateEditQuotation.vue'),
      },
      {
        path: 'sales/quotations/:id/show',
        name: 'SaleConsultandShowQuotation',
        component: () => import('@/views/dashboard/sales/quotations/CreateEditQuotation.vue'),
      },
      {
        path: 'sales/quotations/:id/edit',
        name: 'EditQuotation',
        component: () => import('@/views/dashboard/sales/quotations/CreateEditQuotation.vue'),
      },
      {
        path: 'sales/quotations',
        name: 'ListQuotations',
        component: () => import('@/views/dashboard/sales/quotations/ListQuotations.vue'),
      },
      {
        path: 'sales/customers',
        name: 'SalesConsultantCustomerList',
        component: () => import('@/views/dashboard/sales/customers/List.vue'),
      },
      {
        path: 'notifications',
        name: 'Notifications',
        component: () => import('@/views/dashboard/notifications/List.vue'),
      },
    ],
  },
];

export default dashboard;
