<template>
  <nav class="wrapper__nav">
    <ul class="wrapper__nav-left">
      <li class="wrapper__nav-left-logo">
        <img
          src="@/assets/images/intranort-logo.png"
          alt="Logo intranort"
          class="img-fluid logo"
        />
        <el-button class="responsive__button" type="text" @click="onOpenMenu()">
          <i class="fa fa-bars"></i>
        </el-button>
      </li>
      <!-- <li>
        <el-button type="text">
          <i class="fa-regular fa-calendar"></i>
        </el-button>
      </li>
      <li>
        <el-button type="text">
          <i class="fa-regular fa-message"></i>
        </el-button>
      </li>
      <li>
        <el-button type="text">
          <i class="fa-regular fa-envelope"></i>
        </el-button>
      </li>
      <li>
        <el-button type="text">
          <i class="fa-regular fa-square-check"></i>
        </el-button>
      </li> -->
    </ul>
    <ul class="wrapper__nav-right">
      <li>
        <el-button
          class="responsive__button"
          type="text"
          :disabled="classRoomVideos.length === 0"
          @click="$store.dispatch('ON_SET_SHOWMODALELEARNING', true)"
        >
          <i class="fas fa-graduation-cap"></i>
        </el-button>
      </li>
      <li>
        <el-button class="responsive__button" type="text" @click="onChangeTheme()">
          <template v-if="theme === 'dark'">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-sun"
            >
              <circle cx="12" cy="12" r="5"></circle>
              <line x1="12" y1="1" x2="12" y2="3"></line>
              <line x1="12" y1="21" x2="12" y2="23"></line>
              <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
              <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
              <line x1="1" y1="12" x2="3" y2="12"></line>
              <line x1="21" y1="12" x2="23" y2="12"></line>
              <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
              <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
            </svg>
          </template>
          <template v-else>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-moon"
            >
              <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
            </svg>
          </template>
        </el-button>
      </li>
      <li>
        <el-popover
          placement="bottom"
          width="450"
          trigger="click"
          popper-class="p-0"
        >
          <el-scrollbar
            :style="{ height: menuHeight, overflowX: 'hidden' }"
            class="hide-x"
          >
            <div class="notifications__item">
              <strong class="mr-auto">Notificaciones</strong>
              <el-tag size="small"
                >{{ notifications | unreadNotifications }} nuevas</el-tag
              >
            </div>
            <div
              class="notifications__item"
              v-for="notification in notifications"
              :key="notification.id"
            >
              <img
                :src="notification.sender.avatar"
                :alt="notification.sender.nick"
                :title="notification.sender.nick"
                class="img-fluid notifications__avatar"
              />
              <div class="notifications__content">
                <h6>{{ notification.title }}</h6>
                <span>{{ notification.message }}</span>
                <div>
                  <h5><span>Enviado por:</span> {{ notification.sender.nick }}</h5>
                  <span>{{ notification.date }}</span>
                  <el-button
                    :style="{
                      backgroundColor: notification.is_read ? '#00BFFF' : 'white',
                    }"
                    circle
                    @click="markAsRead(notification.id)"
                  >
                  <i class="fa-solid fa-check"></i>
                  </el-button>
                </div>
              </div>
            </div>
            <div
              class="notifications__item"
              :style="{ position: 'sticky', bottom: '0', backgroundColor: '#FFFFFF', textAlign: 'center', padding: '10px', borderTop: '1px solid rgba(75, 70, 92, 0.12)'}"
            >
              <el-button type="text" @click="goToAllNotifications">Ver todas las notificaciones</el-button>
            </div>
          </el-scrollbar>
          <el-badge slot="reference" :value="notifications | unreadNotifications" class="item">
            <el-button class="responsive__button" type="text">
              <i class="fa-regular fa-bell"></i>
            </el-button>
          </el-badge>
        </el-popover>
      </li>
      <li>
        <el-dropdown trigger="click" placement="bottom-end">
          <div class="el-dropdown-link btn-avatar">
            <div v-if="user">
              <span>{{ user.nick }}</span>
              <small v-if="user.role">{{ user.role.description }}</small>
            </div>
            <img :src="user.avatar" alt="Avatar" />
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item>
                <i class="fa-solid fa-user"></i>
                <span>Mi perfíl</span>
              </el-dropdown-item>
              <el-dropdown-item @click.native="onChangePassword">
                <i class="fa-solid fa-key"></i>
                <span>Cambiar mi contraseña</span>
              </el-dropdown-item>
              <el-dropdown-item divided @click.native="onLogout">
                <i class="fa-solid fa-arrow-right-from-bracket"></i>
                <span>Cerrar sesión</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </li>
    </ul>
  </nav>
</template>

<script>
import { successTitle } from '@/plugins/messages';
import { mapGetters } from 'vuex';

export default {
  name: 'TopbarComponent',
  data: () => ({
    menuHeight: '500px',
  }),
  computed: {
    ...mapGetters(['theme', 'user', 'classRoomVideos', 'notifications']),
  },
  watch: {
    $route: {
      handler: async function (route) {
        const params = {
          route: route.path,
        };
        await this.$axios
          .get('classroom/section/videos', { params })
          .then((response) => {
            this.$store.dispatch('ON_SET_CLASSROOMVIDEOS', response.data.data);
          });
      },
      immediate: true,
    },
    user: {
      handler: async function (newUser) {
        if (newUser) {
          try {
            const response = await this.$axios.get('notifications');
            this.$store.dispatch('ON_SET_NOTIFICATIONS', response.data.data);
          } catch (error) {
            console.error('Error al cargar las notificaciones:', error);
          }
        }
      },
      immediate: true,
    },
  },
  filters: {
    unreadNotifications(notifications) {
      return notifications.filter((n) => n.is_read === false).length;
    },
  },
  methods: {
    goToAllNotifications() {
      this.$router.push({ name: 'Notifications' });
    },
    onChangePassword() {
      this.$store.dispatch('ON_SHOW_MODAL_CHANGE_PASSWORD');
    },
    onLogout() {
      this.$axios({
        method: 'POST',
        url: `${process.env.VUE_APP_API_URL_BASE}logout`,
      }).then((response) => {
        this.$notify({
          title: successTitle,
          message: response.data.message,
          type: 'success',
        });
        this.$auth.removeToken();
        this.$router.push({ name: 'Login' });
      });
    },
    markAsRead(notificationId) {
      this.$store.dispatch('ON_MARK_NOTIFICATION_AS_READ', notificationId);
    },
    onOpenMenu() {
      this.$store.commit('ON_OPEN_MENU');
    },
    onChangeTheme() {
      this.$store.commit('ON_CHANGE_THEME');
    },
  },
};
</script>
<style scoped>
@media (max-width: 480px) {
  .logo {
    max-width: 80px;
  }
  li {
    margin: 0;
  }
  .btn-avatar img {
    max-width: 20px;
  }

  .btn-avatar span {
    font-size: 10px;
  }

  .btn-avatar small {

    font-size: 10px;
  }
  .responsive__button {
    max-width: 30px;
  }
}
</style>
